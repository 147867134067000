/* FileUpload.css */

.file-upload-container {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
}

/* Heading styles */
.file-upload-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Container for form and images */
.form-and-images {
  display: flex;
  flex-direction: column; /* Stack vertically on small screens */
  gap: 20px;
  width: 100%;
  align-items: center; /* Center items horizontally */
}

/* Form container styles */
.form-container {
  width: 100%;
  max-width: 600px; /* Ensure form container does not exceed a certain width */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

/* Form styles */
.form-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Input and button styles */
.form-container input[type="text"],
.form-container input[type="file"],
.form-container button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

/* Text inputs */
.form-container input[type="text"] {
  width: 100%;
}

/* File inputs */
.form-container input[type="file"] {
  border: none;
}

/* Button styles */
.form-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  font-size: 18px;
}

/* Button hover state */
.form-container button:hover {
  background-color: #0056b3;
}

/* Success message styles */
.success-message {
  color: green;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

/* Images container styles */
.images-container {
  width: 100%;
  max-width: 600px; /* Ensure images container does not exceed a certain width */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

/* Individual image styles */
.images-container .image {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

/* Responsive styles */
@media (min-width: 768px) {
  .form-and-images {
    flex-direction: row; /* Row layout for larger screens */
  }

  .form-container {
    max-width: 600px;
  }

  .images-container {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .form-container {
    max-width: 800px; /* Larger width for larger screens */
  }

  .images-container .image {
    max-width: 300px; /* Larger image size for larger screens */
  }
}

@media (max-width: 576px) {
  .file-upload-container {
    padding: 10px;
  }

  .form-container form {
    padding: 15px;
  }

  .form-container button {
    padding: 10px 15px;
    font-size: 16px;
  }
}
