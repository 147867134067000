.Navbar{
    border-radius: 20px;
    background-color: rgba(238, 230, 236, 0.473);
    text-decoration: none;
    list-style: none;
    align-items: center;
    justify-content: center;
    display: block;
}
.Navbar ul{
    display: inline-block;
    /* overflow: auto; */
    text-decoration: none;
    list-style: none;
}
.Navbar ul li{
    /* list-style: none; */
    /* display: flex; */
    float: left;
    margin-left: 23px;
    padding: 20px 30px;
    text-decoration: none;
    
    /* justify-content: center; */
}
.Navbar ul li a{
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: block;}
