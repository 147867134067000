*{
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.headlines{
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: justify;
    border-radius: 10px;
    border: ridge;
    background-color: rgb(255, 81, 0);
}
.text{
    margin-bottom: 20px;
    text-align: justify;
    border-radius: 10px;
    border: ridge;
    background-color: rgb(236, 233, 40);
}
.text1{
    margin-bottom: 20px;
    text-align: justify;
    border-radius: 10px;
    border: ridge;
    background-color: rgb(33, 238, 67);
}
.Row {
    display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}

.W img{
    height: 300px;
    margin: 22px;
    width: 400px;
    border: 2px solid rgba(32, 32, 31, 0.867);
    border-radius: 13px;
    /* box-shadow: 4px 7px 7px 0px #000042; */
    cursor:pointer;
    margin:10px;
    transition: 40ms;
}
.w img:hover{
    filter:grayscale(1);
    transform: scale(1.03);
}
.anchortag p{
 font-weight: bolder;
 font-size: large;
}
.anchortag button{
    background-color: rgb(250, 246, 246);
    /* border: ridge; */
    /* border-color: black; */
    border-radius: 5px;
    text-decoration: none;
    float: left;
    margin: 10px;
    
}
.anchortag a{
    text-decoration: none;
    margin: 20px;
    padding: 10px;

}
.anchortag{
    display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   margin-right: 2px;

}
.container{
    width:100%;
    min-height: 100%;
    background-color: beige;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 90px;
}
.content-section{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 20px;
}
.content-section .card{
    flex: 1;
    margin: 5px;
    border: ridge;
    margin-right: 20px;
}
.content-section .card img{
    width: 40%;
    height: 42%;
}
.content-section .card a{
    text-decoration: none;
    background-color: rgb(231, 226, 225);
    font-weight: bold;
    border: ridge;
    border-color: rgb(224, 213, 213);
    border-radius: 5px;
    font-style: normal;
    margin-bottom: 10px;

    
}
.ShopCall p{
    margin-top: 10px;
    background-color: rgb(250, 234, 8);
    font-weight: bolder;
}
.Note{
    margin: 10px;
    border: ridge;
    border-radius: 10px;
    background-color: rgb(238, 219, 45);
    text-align: justify;
    justify-content: center;
}
.countries{
    margin: 5px;
    border: ridge;
    border-radius: 10px;
    background-color: blanchedalmond;
}

